import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-submit-btn-spinner"
export default class extends Controller {
  static targets = [ "spinner", "text" ]
  static values = { saving: String }

  connect() {
  }

  show(event) {
    event.preventDefault()

    this.spinnerTarget.classList.remove("hidden")
    this.textTarget.innerText = this.savingValue

    event.target.setAttribute("disabled", "true")
    this.element.submit()
  }
}
